import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import track from "../../components/track"

const PrivacyPolicy = () => {

  const pageTitle = 'Contact';
  const pageDescription = 'Contact';
  const pageName = "Contact";

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="patient" pageSection="patient-contact" pageClass="contact"  pageName={pageName}>
      <div className="container">
        <h1>General information</h1>
        <h3>Corporate HQ</h3>
        <p>Tel:&nbsp;<a class="blue-link" href="tel:18332330115">1-833-233-0115</a><br />
        <a class="blue-link" href="mailto:info@valinorrx.com">info@valinorrx.com</a></p>
        
        <h3>Other Inquiries</h3>
        <p><span className="bold">General Inquiries or Copay-Related Questions</span><br /><a class="blue-link" href="mailto:info@valinorrx.com">info@valinorrx.com</a></p>
        <p><span className="bold">Product-Specific Questions or Medical Information Requests</span><br />Call <a class="blue-link" href="tel:18775922337">1-877-592-2337</a></p>
        <p><span className="bold">Business Development &mdash; for Partnering Inquiries</span><br /><a class="blue-link" href="mailto:info@valinorrx.com">info@valinorrx.com</a></p>
        <h3>Adverse Reactions</h3>
        <p>Adverse reactions can be reported to Valinor Pharma, LLC, at <a class="blue-link" href="tel:18775922337">1-877-592-2337</a> or FDA at <a class="blue-link" href="tel:18003321088">1-800-FDA-1088</a> or <a class="blue-link" href="http://www.fda.gov/medwatch" target="_blank" id="isi-patient-fda">www.fda.gov/medwatch</a>.</p>

      </div>
    </Layout>
  )
}

export default PrivacyPolicy
